export const lightTheme = {
  body: "#FFF",
  navbar: "#FFF",
  h1: "#121214",
  h1Invert: "#E8EAEB",
  text: "#6D6D6B",
  toggleBorder: "#FFF",
  background: "#fff",
  greySection: "#f8f8f7",
  whiteBase: "#fff",
  whiteRgba4: "rgba(255, 255, 255, 0.35)",
  green: "#29D9D5",
  arrow: "#29D9D5",
  footer: "#121214",
  verticalTime: "#C7C8C9",
  bgTimeline: "#fff",
  cardBg: "#fff",
  extLogo: "#e1d8cd",
  headers: "#121214",
  borderMap: "#c6c8c9",
}
export const darkTheme = {
  body: "#0A0303",
  navbar: "#FFF",
  h1: "#ddd",
  h1Invert: "#E8EAEB",
  text: "#ccc",
  toggleBorder: "#6B8096",
  background: "#121214",
  greySection: "#141417",
  whiteBase: "#0A0303",
  whiteRgba4: "rgba(0, 0, 0, 0.35)",
  green: "#29D9D5",
  arrow: "#29D9D5",
  footer: "#121214",
  verticalTime: "#f8f8f7",
  bgTimeline: "#141417",
  cardBg: "#000",
  extLogo: "#e1d8cd",
  headers: "#e1d8cd",
  borderMap: "#29d9d5",
}
