import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../../images/logo-navbar.svg"
const LinkLogo = styled(Link)`color: ${({ theme }) => theme.text};text-decoration: none;
text-align: center;
font-family: "Celias";
font-style: normal;`
const NavItem = styled(Link)`
  color: ${({ theme }) => theme.text};
  position: relative;
  top: -35px;
  margin: 14px 15px;
  text-decoration: none;
  text-align: center;
  font-family: "Celias";
  font-style: normal;
  :hover {
    text-align: center;
    text-decoration: underline ${({ theme }) => theme.green} 2px;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.green};
    text-underline-offset: 8px;
    ::before {
      content: "";
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.green};
      visibility: hidden;
      transition: all 0.5s ease-in-out;
    }
  }
  :active {
    color: ${({ theme }) => theme.green};
  }
  @media (max-width: 768px) {
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    text-align: center;
    z-index: 10;
    top: 75px;
    background-color: ${({ theme }) => theme.background};
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
`
const LogoNav = styled(Logo)`
  width: 70px;
  height: auto;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.extLogo};
  background-color: ${({ theme }) => theme.background};

  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -30px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -20px;
  @media (max-width: 768px) {
    display: none;
  }
`
const FixLogo = styled.img`
  position: absolute;
  left: 10px;
  top: 10px;
  width: 60px;
`
const FixedNameItalic = styled.p`
  font-family: "Celias";
  font-style: italic;
  position: absolute;
  left: 80px;
  top: -6px;
  font-size: 1.2em;  @media (max-width: 1680px) {font-size: .9em;  top: 6px; }
`
const FixedName = styled.span`
  font-family: "Celias";
  font-style: normal;
  margin-top: 0 !important;
  font-size: 0.75em;
`
const NavbarLinks = () => {
  return (
    <> <LinkLogo to="/">
      <FixLogo
        src="https://res.cloudinary.com/denq2jckc/image/upload/v1632631279/Navigation/logo-fixed_j6nfbz.svg"
        alt="Advance manufacturer | Logo"
      />
      <FixedNameItalic>
        Advance
        <br />
        <FixedName>Manufacturers</FixedName>
      </FixedNameItalic></LinkLogo>

      <NavItem to="/about-us">About Us</NavItem>
      <NavItem to="/production-process">Production</NavItem>
      <NavItem to="/design-development">Design &amp; Development</NavItem>
      <Link to="/">
        <LogoNav></LogoNav>
      </Link>
      <NavItem to="/gallery">Gallery</NavItem>
      <NavItem to="/news-events">Events</NavItem>{" "}
      <NavItem to="/sustainability">Certification & Sustainability</NavItem>
      <NavItem to="/contact-us">Contact Us</NavItem>
    </>
  )
}

export default NavbarLinks
