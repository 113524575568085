import React from "react"
import { func, string } from "prop-types"
import styled from "styled-components"
import { ContrastDrop } from "@styled-icons/remix-line/"

const Drop = styled(ContrastDrop)`
  fill: ${({ theme }) => theme.text};
  width: 30px;
  position: fixed;
  right: 10px;
  top: 20px;
`

const Toggle = ({ toggleTheme }) => {
  return <Drop onClick={toggleTheme} />
}
Toggle.propTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired,
}
export default Toggle
