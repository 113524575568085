import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"
import { Flex, Box } from "rebass"
import FooterLogo from "../../images/logo-navbar.svg"
import { PhoneOutline } from "@styled-icons/evaicons-outline"
import { Map } from "@styled-icons/boxicons-solid"
import { Email } from "@styled-icons/entypo"
import { Facebook, Instagram, Twitter } from "@styled-icons/boxicons-logos"
import { FacebookMessenger } from "@styled-icons/fa-brands"
import SpanGreen from "../Typography/Span/SpanGreen"
const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "worldFooter.jpg" }) {
          childImageSharp {
            fluid(quality: 95, maxWidth: 3200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <>
      <BackgroundImage
        Tag="section"
        className={className}
        fluid={imageData}
        backgroundColor={`#040e18`}
      >
        <Section>
          <Flex flexWrap="wrap" pt={[2, 2, 4]}>
            <Box width={[1, 1 / 2, 2 / 8]} p={[3, 2, 2]}>
              <FootLogo />
              <WhiteP>MANUFACTURERS OF FINE GOLD &amp; SILVER JEWELRY</WhiteP>
            </Box>
            <Box width={[1, 1 / 2, 3 / 8]} p={[3, 2, 2]}>
              <WhiteTittle>Advance Manufacturers Co., Ltd</WhiteTittle>
              <Ul>
                <Li>
                  <GreenPhone />
                  +662-326-0985
                </Li>
                <Li>
                  <GreenMap />
                  366 Moo 4 Chalongkrung Road, <br />
                  <PadLeft>
                    Lat Kra Bang Industrial Estate,
                    <br /> Bangkok, 10520 Thailand
                  </PadLeft>
                </Li>
                <Li>
                  <GreenMail />
                  <WhiteA href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#97;&#100;&#118;&#97;&#110;&#99;&#101;&#64;&#97;&#100;&#118;&#109;&#102;&#114;&#46;&#99;&#111;&#109;">
                    &#97;&#100;&#118;&#97;&#110;&#99;&#101;&#64;&#97;&#100;&#118;&#109;&#102;&#114;&#46;&#99;&#111;&#109;
                  </WhiteA>{" "}
                </Li>
              </Ul>
            </Box>
            <Box width={[1, 1 / 2, 2 / 8]} p={[3, 2, 1]} pt={[0, 0, 5]}>
              <Ul>
                <Li>
                  <FootLink to="/">Home</FootLink>
                </Li>
                <Li>
                  <FootLink to="/about-us">About us</FootLink>
                </Li>
                <Li>
                  <FootLink to="/design-development">
                    Design &amp; Development
                  </FootLink>
                </Li>
                <Li>
                  <FootLink to="/production-process">
                    Production Process
                  </FootLink>
                </Li>
                <Li>
                  <FootLink to="/gallery">Gallery </FootLink>
                </Li>
                <Li>
                  <FootLink to="/news-events">News &amp; Events</FootLink>
                </Li>
              </Ul>
            </Box>
            <Box width={[1, 1 / 2, 1 / 8]} pt={[0, 0, 5]}>
              <SpacePad>
                <PadSocial
                  href="https://facebook.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <WhiteFacebookCircle />
                </PadSocial>
              </SpacePad>
              <SpacePad>
                <PadSocial
                  href="https://facebook.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <WhiteFacebookMessenger />
                </PadSocial>
              </SpacePad>
              <SpacePad>
                <PadSocial
                  href="https://facebook.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <WhiteInstagram />
                </PadSocial>
              </SpacePad>
              <SpacePad>
                <PadSocial
                  href="https://facebook.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <WhiteTwitter />
                </PadSocial>
              </SpacePad>
            </Box>
          </Flex>
        </Section>
        <Border>
          <SectionEnd>
            <Flex flexWrap="wrap">
              <Box width={[1, 1 / 2]}>
                <LeftWhiteP>
                  © {new Date().getFullYear()} <SpanGreen>Advance</SpanGreen>.
                  All Rights Reserved.
                </LeftWhiteP>
              </Box>
              <Box width={[1, 1 / 2]}>
                <RightWhiteP>
                  <FootLink to="/privacy-policy">Privacy Policy</FootLink> |
                  <FootLink to="/term-of-use"> Terms of Use</FootLink>
                </RightWhiteP>
              </Box>
            </Flex>
          </SectionEnd>
        </Border>
      </BackgroundImage>
    </>
  )
}
const Section = styled.div`
  padding: 0 10vw 1vh;
`
const SectionEnd = styled.div`
  padding: 0vh 10vw 1vh;
`
const FootLogo = styled(FooterLogo)`
  width: 8vw;
  display: block;
  margin: auto;
  @media (max-width: 768px) {
    width: 30vw;
  }
`
const WhiteTittle = styled.h4`
  font-size: calc(0.7rem + 0.7vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-align: left;
  @media (max-width: 768px) {
    font-size: calc(0.8em + 0.8vw);
  }
`
const WhiteP = styled.p`
  font-size: calc(0.6rem + 0.5vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-align: center;
`
const WhiteA = styled.a`
  font-size: calc(0.6rem + 0.5vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
`
const LeftWhiteP = styled.p`
  font-size: calc(0.6rem + 0.5vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-align: left;
  padding-left: 0.5vw;
`
const RightWhiteP = styled.p`
  font-size: calc(0.6rem + 0.5vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-align: right;
  padding-left: 0.5vw;
  @media (max-width: 768px) {
    font-size: calc(0.8em + 0.8vw);
  }
`

const StyledBackgroundSection = styled(BackgroundSection)`
  background: no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`
const Ul = styled.ul`
  list-style-type: none;
  list-style-position: outside;
`
const Li = styled.li`
  font-size: calc(0.5rem + 0.5vw);
  color: ${({ theme }) => theme.h1invert};
  font-family: "Celias";
  line-height: 2;
  @media (max-width: 768px) {
    font-size: calc(0.8em + 0.6vw);
  }
`
const GreenPhone = styled(PhoneOutline)`
  color: #29d9d5;
  width: 1.4em;
  padding-right: 1em;
`
const GreenMap = styled(Map)`
  color: #29d9d5;
  width: 1.4em;
  padding-right: 1em;
`
const GreenMail = styled(Email)`
  color: #29d9d5;
  width: 1.4em;
  padding-right: 1em;
`
const WhiteFacebookCircle = styled(Facebook)`
  color: #fff;
  border: 1px solid #29d9d5;
  padding-right: 1em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 5px;
  :hover {
    color: ${({ theme }) => theme.green};
    border: 1px solid #29d9d5;
  }
`

const WhiteFacebookMessenger = styled(FacebookMessenger)`
 color: #fff;
  border: 1px solid #29d9d5;
  padding-right: 1em;

  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 5px;
  :hover {
    color: ${({ theme }) => theme.green};
    border: 1px solid #29d9d5;
  }
`
const WhiteInstagram = styled(Instagram)`
  color: #fff;
  border: 1px solid #29d9d5;
  padding-right: 1em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 5px;
  :hover {
    color: ${({ theme }) => theme.green};
    border: 1px solid #29d9d5;
  }
`
const WhiteTwitter = styled(Twitter)`
  color: #fff;
  border: 1px solid #29d9d5;
  padding-right: 1em;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  vertical-align: middle;
  padding: 5px;
  :hover {
    color: ${({ theme }) => theme.green};
    border: 1px solid #29d9d5;
  }
`
const PadLeft = styled.div`
  padding-left: 2.4em;
  padding-top: 0;
  padding-bottom: 0;
`
const Border = styled.div`
  border-top: 1px solid #6d6d6b;
`
const PadSocial = styled.a`
  padding: 1vh 0.5vw;
`
const SpacePad = styled.div`
  padding: 1vh 0px;
  display: inline-flex;
`
const FootLink = styled(Link)`
  font-size: calc(0.6rem + 0.5vw);
  color: ${({ theme }) => theme.h1Invert};
  font-family: "Celias";
  line-height: 1.5;
  text-decoration: none;
  text-transform: capitalize;
  :hover {
    color: ${({ theme }) => theme.green};
  }
  :visited {
    ${({ theme }) => theme.h1Invert};
  }
  @media (max-width: 768px) {
    font-size: calc(0.8em + 0.6vw);
  }
`
export default StyledBackgroundSection
