import { createGlobalStyle } from "styled-components"
import CeliasBlackEot from "../fonts/Celias-Black.eot"
import CeliasBlackwoff2 from "../fonts/Celias-Black.woff2"
import CeliasBlackwoff from "../fonts/Celias-Black.woff"
import CeliasBlackTtf from "../fonts/Celias-Black.ttf"
import CeliasEot from "../fonts/Celias.eot"
import Celiaswoff2 from "../fonts/Celias.woff2"
import Celiaswoff from "../fonts/Celias.woff"
import CeliasTtf from "../fonts/Celias.ttf"
import CeliasBoldEot from "../fonts/Celias-Bold.eot"
import CeliasBoldwoff2 from "../fonts/Celias-Bold.woff2"
import CeliasBoldwoff from "../fonts/Celias-Bold.woff"
import CeliasBoldTtf from "../fonts/Celias-Bold.ttf"
import CeliasMediumEot from "../fonts/Celias-Medium.eot"
import CeliasMediumwoff2 from "../fonts/Celias-Medium.woff2"
import CeliasMediumwoff from "../fonts/Celias-Medium.woff"
import CeliasMediumTtf from "../fonts/Celias-Medium.ttf"
import CeliasMediumItalicEot from "../fonts/Celias-MediumItalic.eot"
import CeliasMediumItalicwoff2 from "../fonts/Celias-MediumItalic.woff2"
import CeliasMediumItalicwoff from "../fonts/Celias-MediumItalic.woff"
import CeliasMediumItalicTtf from "../fonts/Celias-MediumItalic.ttf"
import CeliasThinEot from "../fonts/Celias-Thin.eot"
import CeliasThinwoff2 from "../fonts/Celias-Thin.woff2"
import CeliasThinwoff from "../fonts/Celias-Thin.woff"
import CeliasThinTtf from "../fonts/Celias-Thin.ttf"
import CeliasLightEot from "../fonts/Celias-Light.eot"
import CeliasLightwoff2 from "../fonts/Celias-Light.woff2"
import CeliasLightwoff from "../fonts/Celias-Light.woff"
import CeliasLightTtf from "../fonts/Celias-Light.ttf"
import OFLGoudyStMEot from "../fonts/OFLGoudyStM.eot"
import OFLGoudyStMwoff2 from "../fonts/OFLGoudyStM.woff2"
import OFLGoudyStMwoff from "../fonts/OFLGoudyStM.woff"
import OFLGoudyStMTtf from "../fonts/OFLGoudyStM.ttf"
import OFLGoudyStMItalicEot from "../fonts/OFLGoudyStM-Italic.eot"
import OFLGoudyStMItalicwoff2 from "../fonts/OFLGoudyStM-Italic.woff2"
import OFLGoudyStMItalicwoff from "../fonts/OFLGoudyStM-Italic.woff"
import OFLGoudyStMItalicTtf from "../fonts/OFLGoudyStM-Italic.ttf"

const GlobalStyles = createGlobalStyle`
@font-face {
    font-family: 'Celias';
    src: url('${CeliasBlackEot}');
    src: url('${CeliasBlackEot}?#iefix') format('embedded-opentype'),
        url('${CeliasBlackwoff2}') format('woff2'),
        url('${CeliasBlackwoff}') format('woff'),
        url('${CeliasBlackTtf}') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OFL Sorts Mill Goudy';
    src: url('${OFLGoudyStMItalicEot}');
    src: url('${OFLGoudyStMEot}?#iefix') format('embedded-opentype'),
        url('${OFLGoudyStMItalicwoff2}') format('woff2'),
        url('${OFLGoudyStMItalicwoff}') format('woff'),
        url('${OFLGoudyStMItalicTtf}') format('truetype');
    
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasBoldEot}');
    src: url('${CeliasBoldEot}?#iefix') format('embedded-opentype'),
        url('${CeliasBoldwoff2}') format('woff2'),
        url('${CeliasBoldwoff}') format('woff'),
        url('${CeliasBoldTtf}') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasEot}');
    src: url('${CeliasEot}?#iefix') format('embedded-opentype'),
        url('${Celiaswoff2}') format('woff2'),
        url('${Celiaswoff}') format('woff'),
        url('${CeliasTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OFL Sorts Mill Goudy';
    src: url('${OFLGoudyStMEot}');
    src: url('${OFLGoudyStMEot}?#iefix') format('embedded-opentype'),
        url('${OFLGoudyStMwoff2}') format('woff2'),
        url('${OFLGoudyStMwoff}') format('woff'),
        url('${OFLGoudyStMTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasMediumEot}');
    src: url('${CeliasMediumEot}?#iefix') format('embedded-opentype'),
        url('${CeliasMediumwoff2}') format('woff2'),
        url('${CeliasMediumwoff}') format('woff'),
        url('${CeliasMediumTtf}') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasThinEot}');
    src: url('${CeliasThinEot}?#iefix') format('embedded-opentype'),
        url('${CeliasThinwoff2}') format('woff2'),
        url('${CeliasThinwoff}') format('woff'),
        url('${CeliasThinTtf}') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasMediumItalicEot}');
    src: url('${CeliasMediumItalicEot}?#iefix') format('embedded-opentype'),
        url('${CeliasMediumItalicwoff2}') format('woff2'),
        url('${CeliasMediumItalicwoff}') format('woff'),
        url('${CeliasMediumItalicTtf}') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Celias';
    src: url('${CeliasLightEot}');
    src: url('${CeliasLightEot}?#iefix') format('embedded-opentype'),
        url('${CeliasLightwoff2}') format('woff2'),
        url('${CeliasLightwoff}') format('woff'),
        url('${CeliasLightTtf}') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  
  
  }
  html {
 touch-action:manipulation;
 font-feature-settings:"rlig" 1,"calt" 0;
 text-rendering:optimizeLegibility;
 -webkit-font-smoothing:antialiased;
 -moz-osx-font-smoothing:grayscale;
 max-width:100vw;
}
h1{font-family: 'OFL Sorts Mill Goudy';}
.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {position:absolute;top:-23.5%;font-size:2em;color:${({
  theme,
}) => theme.h1};}
.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover{color:${({
  theme,
}) => theme.text};}
.alice-carousel__prev-btn-item {right:2.5vw}
.alice-carousel__next-btn-item  {right:.5vw}
@media only screen and (max-width: 600px) {
  .alice-carousel__prev-btn-item {right:-20px;top:-55%}
.alice-carousel__next-btn-item  {right:-50px;top:-55%}
}

input, optgroup, select, textarea {
    border:none;display: block;background-color:transparent;
}
label{text-transform:uppercase}
.form-control{min-width:80%!important;}
[data-rmiz-wrap='visible'], [data-rmiz-wrap='hidden'] {
    width: 100%;
    height: 100%;
    display: block;
}
`

export default GlobalStyles
