/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import GlobalStyles from "./GlobalStyles"
import { ThemeProvider } from "styled-components"
import { lightTheme, darkTheme } from "./Themes"
import { useDarkMode } from "./useDarkMode"
import styled from "styled-components"
import Toggler from "./Toogler"
import NavbarLinks from "./Routes/NavbarLinks"

import StyledBackgroundSection from "./Footer/Footer"

const Navigation = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  flex-flow: row nowrap;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  height: 64px;
  padding-top: 5px;

  background-color: ${({ theme }) => theme.background};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
`
const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 1vw;
  float: right;

  @media (max-width: 768px) {
    display: flex;
    float: left;
  }
`
const Navbox = styled.div`
  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding: 1vh 2.5vw 3vh;
    background-color: ${({ theme }) => theme.background};
    top: 60px;
    left: ${props => (props.open ? "-110%" : "0")};
    min-height: 100vh;
  }
`
const Hamburger = styled.div`
  background-color: ${({ theme }) => theme.text};
  width: 30px;
  height: 3px;
  left: 20px;
  top: 30px;
  position: absolute;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${({ theme }) => theme.text};
    content: "";
    position: absolute;
    transition: all 0.3s linear;
    left: 0;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const Layout = ({ children }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [theme, themeToggler] = useDarkMode()

  const themeMode = theme === "dark" ? darkTheme : lightTheme
  return (
    <ThemeProvider theme={themeMode}>
      <>
        <React.Fragment>
          <GlobalStyles />
          <Navigation p={2} alignItems="center">
            <Toggle
              navbarOpen={navbarOpen}
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? <Hamburger open /> : <Hamburger />}
            </Toggle>
            {navbarOpen ? (
              <Navbox>
                <NavbarLinks />
              </Navbox>
            ) : (
              <Navbox open>
                <NavbarLinks />
              </Navbox>
            )}
            <Toggler theme={theme} toggleTheme={themeToggler} />
          </Navigation>
          <main>{children}</main>
          <StyledBackgroundSection />
        </React.Fragment>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
